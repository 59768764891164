import React from 'react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { FaPhone } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'

export default function contact() {
    return (
        <section className="contact py-5">
            <div className="container text-white mw-100 px-5">
                <div className="row">
                    <div className="col-10 col-sm-8 col-md-6 col-lg-4 mx-auto my-3">
                        <div className="card bg-transparent">
                            <div className="card-header mx-auto">
                                <FaMapMarkerAlt size="50px"/>
                            </div>
                            <div className="card-body text-center">
                                <p className="card-text">
                                    Skinnarsvägen 6 <br />
                                    65450 Solf <br />
                                    Korsholm
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-10 col-sm-8 col-md-6 col-lg-4 mx-auto my-3">
                        <div className="card bg-transparent">
                            <div className="card-header mx-auto">
                                <FaPhone size="50px"/>
                            </div>
                            <div className="card-body text-center">
                                <p className="card-text">
                                    Sven Hällmark <br />
                                    +358 50 542 1893
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-10 col-sm-8 col-md-6 col-lg-4 mx-auto my-3">
                        <div className="card bg-transparent">
                            <div className="card-header mx-auto">
                                <FaEnvelope size="50px"/>
                            </div>
                            <div className="card-body text-center">
                                <p className="card-text ">
                                    <a className="text-white" href="mailto:sven.hellmark@shmaleri.fi">sven.hallmark@shmaleri.fi</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </section>
    )
}

import React from 'react'
import Img from 'gatsby-image'

export default function service({ service, desc }) {
    return (
        <div className="col-10 col-sm-8 col-md-6 col-lg-3 mx-auto my-3">
            <div className="card" style={{minHeight:"100%"}}>
                <Img fixed={service.childImageSharp.fixed} className="card-img-top mw-100 mx-auto" />
                <div className="card-body text-center lead">
                    {desc}
                </div>
            </div>
        </div>
    )
}

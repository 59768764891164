import React from 'react'
import Service from './service'
import {StaticQuery,graphql,Link} from 'gatsby'
import Title from './title'

const getServiceImages = graphql`{
    services:allFile(
        filter: {
            extension: { regex: "/(jpg)/" }
            relativeDirectory: { eq: "services" }
        }
        ) {
        edges {
            node {
                id
                base
                childImageSharp {
                    fixed(height: 250, width: 400, cropFocus: CENTER) {
                        ...GatsbyImageSharpFixed_tracedSVG
                    }
                }
            }
        }
    }
}`

const serviceDescChair = "Målning av dörrar, kök, skåp och möbler."
const serviceDescRoller = "Målning och tapetsering av såväl nybyggen som saneringsobjekt."
const serviceDescMarble = "Graining, marmorering, spännpapp och andra traditionella målarmetoder."
const serviceDescHouse = "Målning, tvättning och reparation av fasader."
const serviceDescChairFI = "Ovien, keittiön, kaapien ja kalusteiden maalaukset."
const serviceDescRollerFI = "Maalausta ja tapetointia uudis- ja saneerauskohteisiin."
const serviceDescMarbleFI = "Graining ja marmorikuviointi, kiristyspahvi, paperi ja muut perinteiset maalausmenetelmät."
const serviceDescHouseFI = "Julkisivusiivous, maalaus ja kunnostus."
const serviceDescDefault = "Fix me"

export default function services({ lang }) {
    return (
        <div>
            <StaticQuery query={getServiceImages} render={data => {
                return(<section className="py-5 bg-light">
                    <div className="container mw-100">
                        { lang === "fi" ? (<Title title="Palvelemme Vaasan seudulla"/>) : (<Title title="Vi betjänar i Vasa med omnejd"/>) }
                        <div className="row">
                            {
                                data.services.edges.map(({node:service})=>{
                                    switch (service.base) {
                                        case "chair.jpg":   return <Service key={service.id} service={service} desc={ lang === "fi" ? (serviceDescChairFI) : (serviceDescChair) } lang={lang} />
                                        case "roller.jpg":  return <Service key={service.id} service={service} desc={ lang === "fi" ? (serviceDescRollerFI) : (serviceDescRoller) } lang={lang} />
                                        case "marble.jpg":  return <Service key={service.id} service={service} desc={ lang === "fi" ? (serviceDescMarbleFI) : (serviceDescMarble) } lang={lang} />
                                        case "house.jpg":   return <Service key={service.id} service={service} desc={ lang === "fi" ? (serviceDescHouseFI) : (serviceDescHouse) } lang={lang} />
                                        default:            return <Service key={service.id} service={service} desc={serviceDescDefault} lang={lang} />
                                    }
                                })
                            }
                        </div>
                        <div className="row mx-auto my-5 pt-2 align-items-center align-content-center justify-content-center">
                            {lang === "fi" ? 
                            (<Link className="btn btn-outline-primary btn-lg px-4" to="/fi/offert">Pyydä tarjous</Link>)
                            :
                            (<Link className="btn btn-outline-primary btn-lg px-4" to="/offert">Begär offert</Link>)
                            }
                            
                        </div>
                    </div>
                </section>)
            }} />
        </div>
    )        
}

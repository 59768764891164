import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopSection from "../components/topsection"
import Services from "../components/services"
import Contact from "../components/contact"
import PropTypes from "prop-types"

const IndexPage = ({ data, location }) => (
  <Layout location={location} lang="sv">
    <SEO
      title="Välkommen"
      keywords={[
        "Målare",
        "Målare Vasa",
        "Målare Korsholm",
        "Målarbeten",
        "Målarbeten Vasa",
        "Målarbeten Korsholm",
        "Fasadrenovering",
        "Fasadrenovering Vasa",
        "Fasadrenovering Korsholm",
        "Fasadtvätt",
        "Fasadtvätt Vasa",
        "Fasadtvätt Korsholm",
        "Taktvätt",
        "Taktvätt Vasa",
        "Taktvätt Korsholm",
        "Takmålning",
        "Takmålning Vasa",
        "Takmålning Korsholm",
      ]}
    />
    <TopSection
      img={data.img.childImageSharp.fluid}
      title="SH-Måleri AB"
      styleClass="default-background"
      lang="sv"
    />
    <Services location={location} lang="sv" />
    <Contact lang="sv" />
  </Layout>
)

IndexPage.propTypes = {
  location: PropTypes.object,
}

export const query = graphql`
  {
    img: file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default IndexPage
